<template lang="">
    <div>
        <div class="headers">
            <span @click="goUp()"></span>
            <span>运输前核验并开始运输</span>
            <span @click="islogout">退出</span>
        </div>
        <div class="StartTransportation">
         <p>拖运现场影像：（拍摄三张现场情况）</p>
        
          
          <div v-if="!isWechat">
            <van-uploader v-model="fileList_g" multiple :max-count="1" :after-read="afterRead_g" :before-delete="beforeDelete_g"/>
            <van-uploader v-model="fileList_h" multiple :max-count="1" :after-read="afterRead_h" :before-delete="beforeDelete_h"/>
            <van-uploader v-model="fileList_i" multiple :max-count="1" :after-read="afterRead_i" :before-delete="beforeDelete_i"/>
          </div>
          <div v-else>
              <span class="imgbox" v-for="(item,index) in WXimg" :key="index">
                  <van-image class="vanImage" width="80"  height="80"  :src="item" @click="bigImg(item)" >
                      <van-icon class="iconX" name="close" @click="delImgHan('arrPhoto',index)" />
                  </van-image>
              </span>
              <span v-if="WXimg.length <= 2" class="uploaderImg" @click="WeChooseImage(3,'tuoyun')"> 
                  <van-icon class="iconz" name="plus" />
              </span>
          </div>
        <p>车辆核验影像：（按照指定部位拍摄）</p>
        <div class="img_box">
            <div v-if="!isWechat">
               <van-uploader v-model="fileList_a" multiple :max-count="1" :after-read="afterRead_a" :before-delete="beforeDelete_a"/>
            </div>
            <div v-else style="text-align: center;">
                <van-image v-if="top_top" class="vanImage" width="80"  height="80"  :src="top_top" @click="bigImg(top_top)" >
                    <van-icon class="iconX" name="close" @click="delImgHan('top_top')" />
                </van-image>
                <span v-if="!top_top" class="uploaderImg" @click="WeChooseImage(1,'top_top')"> 
                    <van-icon class="iconz" name="plus" />
                </span>
            </div>
            <span>头部+车顶</span>


        </div>
          <div class="img_box">
            
             <div v-if="!isWechat">
                <van-uploader v-model="fileList_b" multiple :max-count="1" :after-read="afterRead_b" :before-delete="beforeDelete_b"/>
            </div>
            <div v-else style="text-align: center;">
                <van-image v-if="left_top" class="vanImage" width="80"  height="80"  :src="left_top" @click="bigImg(left_top)" >
                    <van-icon class="iconX" name="close" @click="delImgHan('left_top')" />
                </van-image>
                <span v-if="!left_top" class="uploaderImg" @click="WeChooseImage(1,'left_top')"> 
                    <van-icon class="iconz" name="plus" />
                </span>
            </div>
            <span>左侧+车顶</span>
        </div>
          <div class="img_box">
            
            <div v-if="!isWechat">
                <van-uploader v-model="fileList_c" multiple :max-count="1" :after-read="afterRead_c" :before-delete="beforeDelete_c"/>
            </div>
            <div v-else style="text-align: center;">
                <van-image v-if="right_top" class="vanImage" width="80"  height="80"  :src="right_top" @click="bigImg(right_top)" >
                    <van-icon class="iconX" name="close" @click="delImgHan('right_top')" />
                </van-image>
                <span v-if="!right_top" class="uploaderImg" @click="WeChooseImage(1,'right_top')"> 
                    <van-icon class="iconz" name="plus" />
                </span>
            </div>
            <span>右侧+车顶</span>
        </div>
          <div class="img_box">
             <div v-if="!isWechat">
               <van-uploader v-model="fileList_d" multiple :max-count="1" :after-read="afterRead_d" :before-delete="beforeDelete_d"/>
            </div>
            <div v-else style="text-align: center;">
                <van-image v-if="button_top" class="vanImage" width="80"  height="80"  :src="button_top" @click="bigImg(button_top)" >
                    <van-icon class="iconX" name="close" @click="delImgHan('button_top')" />
                </van-image>
                <span v-if="!button_top" class="uploaderImg" @click="WeChooseImage(1,'button_top')"> 
                    <van-icon class="iconz" name="plus" />
                </span>
            </div>
            
            <span>尾部+车顶</span>
        </div>
          <div class="img_box">
            <div v-if="!isWechat">
              <van-uploader v-model="fileList_e" multiple :max-count="1" :after-read="afterRead_e" :before-delete="beforeDelete_e"/>
            </div>
            <div v-else style="text-align: center;">
                <van-image v-if="inside" class="vanImage" width="80"  height="80"  :src="inside" @click="bigImg(inside)" >
                    <van-icon class="iconX" name="close" @click="delImgHan('inside')" />
                </van-image>
                <span v-if="!inside" class="uploaderImg" @click="WeChooseImage(1,'inside')"> 
                    <van-icon class="iconz" name="plus" />
                </span>
            </div>
            <span>机盖内部</span>
        </div>
          <div class="img_box">
             <div v-if="!isWechat">
              <van-uploader v-model="fileList_f" multiple :max-count="1" :after-read="afterRead_f" :before-delete="beforeDelete_f"/>
            </div>
            <div v-else style="text-align: center;">
                <van-image v-if="feature" class="vanImage" width="80"  height="80"  :src="feature" @click="bigImg(feature)" >
                    <van-icon class="iconX" name="close" @click="delImgHan('feature')" />
                </van-image>
                <span v-if="!feature" class="uploaderImg" @click="WeChooseImage(1,'feature')"> 
                    <van-icon class="iconz" name="plus" />
                </span>
            </div>
            
            <span>三元特写</span>
        </div>
      
        <div class="submit" @click="submitImg()">提交照片</div>

        <p>完备性预检：</p>

        <div class="btn_list">
            <span :class="['genre',now == item.id ? 'cur' : '']" v-for="(item,index) in CarClassIf" :key="item.id" @click="changHan(item,index)" >{{item.name}}</span>
        </div>
        <div class="table" >
                <van-row gutter="20" class="table_row" v-for="(item, index) in list[now]" :key="index" >
                    
                    <van-col span="6" class="table_row_col1">
                        <input class="input_van" v-model="item.name"  placeholder="拆车件名称"/>
                    </van-col>
                    <van-col span="6" class="table_row_col2">
                        <input type="number" v-model="item.num" class="input_van"  placeholder="拆车件数量"/>
                    </van-col>
                    <van-col span="12" class="table_row_col3">
                        <van-icon name="cross" class="cha" @click="coldes(item,index)" />
                        <van-radio-group class="table_col_radio" v-model="item.isDestroy" direction="horizontal">
                            <van-radio :name='Number(0)'>未破损</van-radio>
                            <van-radio :name='Number(1)'>已破损</van-radio>
                        </van-radio-group>
                    </van-col>
                </van-row>
                <div class="icon"> 
                    <van-icon @click="addList" name="plus" />
                </div>
            </div>
            <van-button class="button_btn" @click="BtnHan" type="info" style="width: 100%;margin: 0.5rem auto;">提交</van-button>
            
    </div>
    </div>
    
</template>
<script>
import { Toast } from "vant";
import { encipherMent, encryptDecode } from '@/utils/encrypt'
export default {
  data() {
    return {
      remark: "",
      fileList: [],
      vehicleImages: "",
      uploadImgBox: [],
      img_box: [],
      fileList_a: [],
      fileList_b: [],
      fileList_c: [],
      fileList_d: [],
      fileList_e: [],
      fileList_f: [],

      fileList_g: [],
      fileList_h: [],
      fileList_i: [],

      top_top: "", //头部加车顶
      left_top: "", //左侧加车顶
      right_top: "", //右侧加车顶
      button_top: "", //尾部加车顶
      inside: "", //机盖特写
      feature: "", //三元特写
      scene1: "", //三元特写
      scene2: "", //三元特写
      scene3: "", //三元特写

      CarClassIf: [], //类型数据
      Niname: "",
      now: "1", //当前点击的类型
      idx: 0, //当前点击
      totalList: [], //列表集合
      database: [], //最后接口提交的集合
      arrList:[],
      list: {},//列表
      wximagelocalids:[],//判断上传张数
      toast:null,
      WXimg: [],
    };
  },
  created() {
    //获取照片
     let data = encipherMent( JSON.stringify({id:this.$route.query.id}))
        let param = new URLSearchParams();
        param.append("value",data);
    this.$http.post("/index.php/dispatch_img/find",param).then((res) => {
          let result = {}
                        if(res.data.code == -1 || res.data.code == 9){
                            result = res.data
                        }else{
                           result = JSON.parse( encryptDecode(res.data))
                        }
        if (result.code == 0) {
          // let http = "http://192.168.1.50:8000/";
          let http = this.GLOBAL.BASE_URL;;
          if (result.data) {
            if (result.data.top_top.length > 0) {
              // alert("1"+result.data.licensePlate)
              // //console.log(result.data.licensePlate)
              //  //console.log(result.data.licensePlate.replace("_1",""))

              this.fileList_a = [
                {
                  url: http + result.data.top_top.replace("_1", ""),
                },
              ];
              this.top_top = result.data.top_top;
            }
            if (result.data.left_top.length > 0) {
              this.fileList_b = [
                {
                  url: http + result.data.left_top.replace("_1", ""),
                },
              ];
              this.left_top = result.data.left_top;
            }
            if (result.data.right_top.length > 0) {
              this.fileList_c = [
                {
                  url: http + result.data.right_top.replace("_1", ""),
                },
              ];
              this.right_top = result.data.right_top;
            }
            if (result.data.button_top.length > 0) {
              this.fileList_d = [
                {
                  url: http + result.data.button_top.replace("_1", ""),
                },
              ];
              this.button_top = result.data.button_top;
            }
            if (result.data.inside.length > 0) {
              this.fileList_e = [
                {
                  url: http + result.data.inside.replace("_1", ""),
                },
              ];
              this.inside = result.data.inside;
            }
            if (result.data.feature.length > 0) {
              this.fileList_f = [
                {
                  url: http + result.data.feature.replace("_1", ""),
                },
              ];
              this.feature = result.data.feature;
            }
            if (result.data.scene1.length > 0) {
              this.fileList_g = [
                {
                  url: http + result.data.scene1.replace("_1", ""),
                },
              ];
              this.scene1 = result.data.scene1;
              this.WXimg.push(this.scene1)
            }
            if (result.data.scene2.length > 0) {
              this.fileList_h = [
                {
                  url: http + result.data.scene2.replace("_1", ""),
                },
              ];
              this.scene2 = result.data.scene2;
              this.WXimg.push(this.scene2)
            }
            if (result.data.scene3.length > 0) {
              this.fileList_i = [
                {
                  url: http + result.data.scene3.replace("_1", ""),
                },
              ];
              this.scene3 = result.data.scene3;
              this.WXimg.push(this.scene3)
            }
            
          }
        }
      });
    //获取分类
    this.$http
      .post("/index.php/index/Simply", { type: "carClassify" })
      .then((res) => {
        if (res.data.code == 0) {
          this.CarClassIf = res.data.data;
        //   //console.log(res.data.data);
          this.Niname = res.data.data[0].name;
			this.now = res.data.data[0].id
			this.CarClassIf.forEach(i => {
				// this.list[i.id]=[]
				this.$set(this.list,i.id,[])
			});

        } else if (res.data.code == "-1") {
          this.$toast.fail(res.data.msg);
          // this.$router.push("/login");
          this.$router.push({
            path: "/login",
            query: { id: this.$route.query.id, ids: this.$route.query.ids },
          });
        } else {
          this.$toast.fail(res.data.msg);
        }
        // //console.log(res);
      });

      //获取完备检查表
        let asd = encipherMent( JSON.stringify({id:this.$route.query.id}))
        let params = new URLSearchParams();
        params.append("value",asd);
      this.$http.post('/index.php/dispatch_completeness_check_minor/find',params).then(res=>{
          let result = {}
                        if(res.data.code == -1 || res.data.code == 9){
                            result = res.data
                        }else{
                           result = JSON.parse( encryptDecode(res.data))
                        }
          if(result.code == 0){
			  if(result.data.length != 0){
				//console.log(result.data.data)
				  this.arrList = result.data.data
				  this.arrList.forEach((i)=>{
					
					  if(i.data.length!=0){
						  //console.log(i.data)
						  i.data.forEach(j=>{
							  let obj = {
								  'name':j.name,//名称
								  'num':j.num,//数量
								  'isDestroy':j.isDestroy,//单选按钮
							  }
							  this.list[j.carClassifyId].push(obj)
							
							  //console.log(this.list[j.carClassifyId],j,this.list)
						  })
					  }
				  })
                
            }
            
          }
      })

  },
  methods: {
	coldes(item,index){
		this.list[this.now].splice(index,1)
		
	},
    //头部加车顶
    afterRead_a(file) {
       Toast.loading({
        duration: 0,
        message: "上传中...",
        forbidClick: true,
      });
      // //console.log(file)
      // 此时可以自行将文件上传至服务器
      let params = new FormData(); //创建form对象
      params.append("file", file.file);
      params.append("w", 200);
      params.append("h", 360);
      this.$http.post("/index.php/index/upload", params).then((res) => {
        if (res.data.code == 0) {
          //console.log(res.data.data);
          this.top_top = res.data.data;
             Toast.clear();

        }
      });
    },
    //左侧加车顶
    afterRead_b(file) {
       Toast.loading({
         duration: 0,
        message: "上传中...",
        forbidClick: true,
      });
      // //console.log(file)
      // 此时可以自行将文件上传至服务器
      let params = new FormData(); //创建form对象
      params.append("file", file.file);
      params.append("w", 200);
      params.append("h", 360);
      this.$http.post("/index.php/index/upload", params).then((res) => {
        if (res.data.code == 0) {
          //console.log(res.data.data);
          this.left_top = res.data.data;
             Toast.clear();

        }
        // //console.log(this.uploadPicList)
      });
    },
    //右侧加车顶
    afterRead_c(file) {
      const toast = Toast.loading({
         duration: 0,
        message: "上传中...",
        forbidClick: true,
      });
      // //console.log(file)
      // 此时可以自行将文件上传至服务器
      let params = new FormData(); //创建form对象
      params.append("file", file.file);
      params.append("w", 400);
      params.append("h", 300);
      this.$http.post("/index.php/index/upload", params).then((res) => {
        if (res.data.code == 0) {
          //console.log(res.data.data);
          this.right_top = res.data.data;
             Toast.clear();

        }
        // //console.log(this.uploadPicList)
      });
    },
    //尾部加车顶
    afterRead_d(file) {
       Toast.loading({
         duration: 0,
        message: "上传中...",
        forbidClick: true,
      });
      // //console.log(file)
      // 此时可以自行将文件上传至服务器
      let params = new FormData(); //创建form对象
      params.append("file", file.file);
      params.append("w", 400);
      params.append("h", 300);
      this.$http.post("/index.php/index/upload", params).then((res) => {
        if (res.data.code == 0) {
          //console.log(res.data.data);
          this.button_top = res.data.data;
             Toast.clear();

        }
        // //console.log(this.uploadPicList)
      });
    },
    //机盖特写
    afterRead_e(file) {
       Toast.loading({
         duration: 0,
        message: "上传中...",
        forbidClick: true,
      });
      // //console.log(file)
      // 此时可以自行将文件上传至服务器
      let params = new FormData(); //创建form对象
      params.append("file", file.file);
      params.append("w", 400);
      params.append("h", 300);
      this.$http.post("/index.php/index/upload", params).then((res) => {
        if (res.data.code == 0) {
          //console.log(res.data.data);
          this.inside = res.data.data;
             Toast.clear();

        }
        // //console.log(this.uploadPicList)
      });
    },
    //三元特写
    afterRead_f(file) {
      const toast = Toast.loading({
         duration: 0,
        message: "上传中...",
        forbidClick: true,
      });
      // //console.log(file)
      // 此时可以自行将文件上传至服务器
      let params = new FormData(); //创建form对象
      params.append("file", file.file);
      params.append("w", 400);
      params.append("h", 300);
      this.$http.post("/index.php/index/upload", params).then((res) => {
        if (res.data.code == 0) {
          //console.log(res.data.data);
          this.feature = res.data.data;
             Toast.clear();

        }
        // //console.log(this.uploadPicList)
      });
    },
    //照片1
    afterRead_g(file) {
       Toast.loading({
         duration: 0,
        message: "上传中...",
        forbidClick: true,
      });
      // //console.log(file)
      // 此时可以自行将文件上传至服务器
      let params = new FormData(); //创建form对象
      params.append("file", file.file);
      params.append("w", 400);
      params.append("h", 300);
      this.$http.post("/index.php/index/upload", params).then((res) => {
        if (res.data.code == 0) {
          //console.log(res.data.data);
          this.scene1 = res.data.data;
             Toast.clear();

        }
        // //console.log(this.uploadPicList)
      });
    },
    //照片2
    afterRead_h(file) {
       Toast.loading({
         duration: 0,
        message: "上传中...",
        forbidClick: true,
      });
      // //console.log(file)
      // 此时可以自行将文件上传至服务器
      let params = new FormData(); //创建form对象
      params.append("file", file.file);
      params.append("w", 400);
      params.append("h", 300);
      this.$http.post("/index.php/index/upload", params).then((res) => {
        if (res.data.code == 0) {
          //console.log(res.data.data);
          this.scene2 = res.data.data;
             Toast.clear();

        }
        // //console.log(this.uploadPicList)
      });
    },
    //照片3
    afterRead_i(file) {
       Toast.loading({
         duration: 0,
        message: "上传中...",
        forbidClick: true,
      });
      // //console.log(file)
      // 此时可以自行将文件上传至服务器
      let params = new FormData(); //创建form对象
      params.append("file", file.file);
      params.append("w", 400);
      params.append("h", 300);
      this.$http.post("/index.php/index/upload", params).then((res) => {
        if (res.data.code == 0) {
          //console.log(res.data.data);
          this.scene3 = res.data.data;
             Toast.clear();

        }
        // //console.log(this.uploadPicList)
      });
    },
    beforeDelete_a() {
      //   //console.log(res)
      this.top_top = "";
      this.fileList_a = [];
    },
    beforeDelete_b() {
      this.left_top = "";
      this.fileList_b = [];
    },
    beforeDelete_c() {
      this.right_top = "";
      this.fileList_c = [];
    },
    beforeDelete_d() {
      this.button_top = "";
      this.fileList_d = [];
    },
    beforeDelete_e() {
      this.inside = "";
      this.fileList_e = [];
    },
    beforeDelete_f() {
      this.feature = "";
      this.fileList_f = [];
    },
    beforeDelete_g() {
      this.scene1 = "";
      this.fileList_g = [];
    },
    beforeDelete_h() {
      this.scene2 = "";
      this.fileList_h = [];
    },
    beforeDelete_i() {
      this.scene3 = "";
      this.fileList_i = [];
    },
    submitImg() {
      //console.log(this.vehicleImages);
      if(this.WXimg.length!=0){

      } 
      let a1 = this.WXimg.length!=0 ? (this.WXimg[0] ? this.WXimg[0] : '') :this.scene1
      let a2 = this.WXimg.length!=0 ? (this.WXimg[1] ? this.WXimg[1] : '') :this.scene2
      let a3 = this.WXimg.length!=0 ? (this.WXimg[2] ? this.WXimg[2] : '') :this.scene3
      this.$forceUpdate()
      let obj = {
          id: this.$route.query.id,
          top_top: this.top_top,
          left_top: this.left_top,
          right_top: this.right_top,
          button_top: this.button_top,
          inside: this.inside,
          feature: this.feature,

          scene1: a1,
          scene2: a2,
          scene3: a3,
        }
       let asd = encipherMent( JSON.stringify(obj))
        let params = new URLSearchParams();
        params.append("value",asd);
      this.$http
        .post("/index.php/dispatch_img/add",params )
        .then((res) => {
            let result = {}
              if(res.data.code == -1 || res.data.code == 9){
                  result = res.data
              }else{
                  result = JSON.parse( encryptDecode(res.data))
              }
          //console.log(res);
          if (result.code == 0) {
            Toast.success(result.msg);
            // this.$router.go('-1')
          } else {
            Toast.fail(result.msg);
          }
        });
    },
    goUp() {
      this.$router.go("-1");
    },
    changHan(item, index) {
      //点击选择
      this.now = item.id
      this.idx = index
      this.Niname = item.name
    },
    addList() {
      //添加
      let cope = {
        name: "", //名称
        num: "", //数量
        isDestroy: 0, //单选按钮
      };
       this.list[this.now].push(cope);
      
    },
    BtnHan() {
		
		//console.log(this.list,this.arrList)
		for(var i in this.list){
			//console.log(i)
			if(this.list[i].length !=0){
         let asd = encipherMent( JSON.stringify({data:this.list,dispatch_id:this.$route.query.id}))
        let params = new URLSearchParams();
        params.append("value",asd);
				this.$http.post('/index.php/dispatch_completeness_check_minor/add',params).then(res=>{
					  let result = {}
                        if(res.data.code == -1 || res.data.code == 9){
                            result = res.data
                        }else{
                           result = JSON.parse( encryptDecode(res.data))
                        }
					if(result.code == 0){
						this.$router.go("-1");
						Toast.success(result.msg);
						
					}else{
						Toast.success(result.msg)
					}
				})
				break
			}
		}
    },


  },
   mounted () {
            this.wcConfig()
        },
  watch:{
      list:function(){
          //console.log(this.list)
      },
      wximagelocalids:{
        handler(newValue, oldValue){
              if(newValue.length > 0 ){
            this.toast = Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: "上传中..."
            });
            this.wxUpload(newValue[0])
        }else{
            this.toast.clear();
        }
        },
        deep: true,
    }
  }
};
</script>
<style lang="scss" scoped> 
.headers {
  padding: 0 20px;
  background-color: cadetblue;
  height: 1rem;
  line-height: 1rem;
  color: white;
  font-size: 0.4rem;
  display: flex;

  justify-content: space-between;
  span {
    display: inline-block;
  }
  span:nth-child(1) {
    width: 30px;
    height: 30px;
    background-image: url(../../assets/u39.png);
    background-size: 100%;
    margin-top: 8px;
  }
}
.StartTransportation {
  width: 96%;
  margin: 0 auto;
  .van-uploader {
    height: 2.5rem;
    width: 2.5rem;
    margin-left: 0.3rem;
    margin-top: 0.3rem;
    // float: left;
    .van-uploader__wrapper {
      height: 100%;
      width: 100%;
      float: left;
      .van-uploader__upload {
        height: 100%;
        width: 100%;
      }
      .van-uploader__preview {
        height: 100%;
        width: 100%;
        .van-uploader__preview-image {
          height: 100%;
          width: 100%;
        }
      }
      .van-uploader__preview-delete{
        height: 0.5rem;
        width: 0.5rem;
        .van-uploader__preview-delete-icon{
 height: 0.7rem;
        width: 0.5rem;
        }
      }
    }
  }
  p {
    font-size: 0.38rem;
  }
  .img_box {
    height: 4rem;
    font-size: 0.38rem;
    width: 3rem;
    display: inline-block;
    span {
      display: inline-block;
      width: 3rem;
      text-align: center;
    }
    .van-uploader {
      height: 2.5rem;
      width: 2.5rem;
      margin-left: 0.3rem;
      margin-top: 0.3rem;
      // float: left;
      .van-uploader__wrapper {
        height: 100%;
        width: 100%;
        float: left;
        .van-uploader__upload {
          height: 100%;
          width: 100%;
        }
        .van-uploader__preview {
          height: 100%;
          width: 100%;
          .van-uploader__preview-image {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }

  .submit {
    width: 100%;
    height: 0.8rem;
    font-size: 0.38rem;
    line-height: 0.8rem;
    text-align: center;
    color: white;
    margin: 20px auto;
    background-color: rgba(0, 153, 255, 1);
    border-radius: 6px;
  }
  .btn_list {
    .genre {
      // display: inline-block;
      font-size: 0.38rem;
      height: 1rem;
      width: 48%;
      float: left;
      border: 1px solid;
      margin-left: 4px;
      margin-top: 18px;
      background-color: gray;
      line-height: 1rem;
      text-align: center;
      color: white;
      &.cur {
        background-color: #ff6b37;
        color: white;
      }
    }
  }
  .table {
      font-size: 0.38rem;
    width: 100%;
    height: 5rem;
    border: 1px solid;
    overflow: scroll;
    margin-top: 4rem;
    .icon {
      text-align: center;
      line-height: 2rem;
      font-size: 35px;
    }
    .table_row {
      height: 1rem;
      border-bottom: 1px solid #000;

      .table_col_radio {
        margin-top: 0.3rem;
	
        // padding-left: 0.2rem;
      }
    }
    .input_van {
      width: 100%;
      margin-top: 0.17rem;
      border-radius: 6px;
    }
  }
}
.table_row_col3{
    position: relative;
    margin-top: 5px;
    .cha{
        position: absolute;
        top: 0;
        right:3px;
    }
}
    .uploaderImg{
        display: inline-block;
        width: 80px !important;
        height: 80px !important;
        border: 1px solid #eee;
        position: relative;
      
        .iconz{
            position: absolute;
            top: 42%;
            left: 38%;

        }
    }
 .vanImage{
    position: relative;
    margin-right: 10px;
    margin-top: 10px;
        .iconX{
        position: absolute;
        top: 0;
        right: 0;
        font-size: 25px;
        background: #fff;
        color: #000;
    }
}
</style>